.assessmentDetail{
    display: flex;
    width: 100%;
    flex-direction: column;
    .newContainer {
      flex: 6;
      display: flex;
      //background-color: rgb(238, 238, 238);
    .topAssessmentDetail {
        padding: 10px;
        margin: 20px;
        display: flex;
        align-items: center;
         h1 {
          margin-left: 0px;
          color: rgb(150, 148, 148);
          font-size: 40px;
        }
        height: 5%;
        
      }
      .bottomAssessmentDetail {
        padding: 20px;
        margin: 20px;
        align-items: center;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        background-color: white;
        .employeeAssessments {
          justify-content: center;
          width: 70vw;
          height: 60vh;
        }
      }
      .title {
        font-size: 16px;
        color: rgb(179, 179, 179);
        margin-bottom: 20px;
      }
    }


}