.testPage {
    display: flex;
    flex-direction: column;
 // align-items: center;
// justify-content: center;



  .myQuestionCard{
    margin: auto;
    width: 50%;
    height: 100%;
    margin-top: 10%;
  }

  .myTimer {
    display: flex;
    justify-content: end;
    width: 100%;
  }
}
