.logoWhite{
  height: 40px;
  cursor: pointer;
}

.navbar {
  height: 50px;
  border-bottom: 0.5px solid lightgrey;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
 // background-color: lightblue;
  .wrapper {
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
   // background-color: rgb(233, 188, 216);
    .search {
      display: flex;
      align-items: center;
      border: 0.5px solid lightgray;
      padding: 3px;
      input {
            border: none;
            outline: none;
            background: transparent;
            &::placeholder{
                font-size: 12px;
            }
      }
    }
  }
}
