.createAssessment {
  width: 100%;
  display: flex;
  flex-direction: column;
  .newContainer {
    flex: 6;
    display: flex;
    //background-color: rgb(238, 238, 238);
    .topCreateAssessment {
      padding: 10px;
      margin: 20px;
      display: flex;
      align-items: center;
       h1 {
        margin-left: 0px;
        color: rgb(150, 148, 148);
        font-size: 40px;
        align-items: center;
      }
      height: 5%;
      
    }
  }
}
