
.sidebar {
    flex: 1;
    border-right: 0.5px solid lightgray;
    min-height: 100vh;
    background-color: white;
  
    .top {
      background-color: whitw;
      height: 50px;
      display: flex;
      align-items: center;//vertically
      justify-content: center;//horizontally
  
      .logo {
        font-size: 20px;
        font-weight: bold;
        //color: blue;
      }
    }
  
    hr {
      margin: 0%;
      height: 0;
      border: 0.5px solid lightgray;
    }
    .center {
      padding-left: 10px;
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
  
        .title {
          font-size: 10px;
          font-weight: bold;
          color: #999;
          margin-top: 15px;
          margin-bottom: 5px;
        }
  
        li {
          display: flex;
          align-items: center;
          padding: 5px;
          cursor: pointer;
  
          &:hover {
            background-color: #ece8ff;
          }
  
          .icon {
            font-size: 18px;
           // color:#190af3;
          }
  
          span {
            // font-size: 13px;
            font-weight: 600;
            margin-left: 10px;
            color: #888;
          }
        }
      }
    }
    .bottom {
      display: flex;
      align-items: center;
      margin: 10px;
  
      .colorOption{
          width: 20px;
          height: 20px;
          border-radius: 5px;
          border: 1px solid #7451f8;
          cursor: pointer;
          margin: 5px;
  
          &:nth-child(1){
              background-color: whitesmoke;
          }
          &:nth-child(2){
              background-color: black;
          }
      }
  
    }
  }
  