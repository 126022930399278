// .center-align {
//     width: 50%;
//     margin: 0 auto;
//     text-align: center;
// }

.contact-us {
    /* Add some padding and margin to the overall container */
    //padding: 20px;
   // width: 80% !important;
   // text-align: center;
   // margin-left: 25% !important;

    /* Style the heading */
    form{
        display: contents;
    }
    
    h2 {
        font-size: 2em;
        text-align: center;
        margin-bottom: 20px;
        color: #1976d2;
    }

    /* Style the form elements */
    .form-element {
        width: 100%;
        margin-bottom: 20px;
    }

    /* Style the submit button */
    button {
        width: 100%;
        background-color: #1976d2;
        color: white;
        padding: 14px 20px;
        margin: 8px 0;
        border: none;
        cursor: pointer;
        font-size: 1.2em;
    }
}
