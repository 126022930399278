.taskPageMain{
    width: 70%;
    margin: auto;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
    padding: 40px 0px;
    .rowItems{
        width: 90%;
    }
}