.homenavbar {
  background-color: white !important;
  //width: 100%;
  .logoImg{
    // width: 18%;
    height: 44px;
    cursor: pointer;

  }
  .logoImgShort{
    // width: 18%;
    height: 34px;
    cursor: pointer;
    margin: auto;
  }

  .navGrid{
    margin-left: 24%;
    .gridItem{
      padding-left: 0 !important;
      a:link {
        text-decoration: none;
        color: #1976d2;
      }
    }
  }
  .iconButton{
    color: #1976d2;
  }
  .myMenuItem{
    color: red;
  }
/*   .pageLinks {
    flex-grow: 1;
    display: flex;
    flex-direction: row-reverse;
    text-transform: none !important;
    margin: 12px;
      text-decoration: none;
    
    .pagesButtons {
      margin-top: 2;
      margin-bottom: 2;
      color: black;
      display: block;
      text-transform: none;
      margin-left: 12px;
    }
    .loginButton {
      margin-top: 2;
      margin-bottom: 2;
      color: black;
      display: block;
      text-transform: none;
    }
    .bookADemoButton {
      margin-top: 2;
      margin-bottom: 2;
      color: black;
      display: block;
      text-transform: none;
    }
    .bookADemo{
      color: #ffffff;
			background-color: #004380 !important;
			font-size: 19px;
			border: 1px solid #2d63c8;
			border-radius: 26px;
			padding: 10px 30px;
			letter-spacing: 1px;
			cursor: pointer;
      box-shadow: 2px 2px 5px #888888;
    }
    .loginbtn{
      color: #004380;
			background-color: #ffffff;
			font-size: 19px;
			border: 1px solid #ffffff;
			border-radius: 26px;
			padding: 10px 45px;
			letter-spacing: 1px;
			cursor: pointer;
      box-shadow: 2px 2px 5px #888888;
    }
  } */
}
