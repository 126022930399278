.employee {
    display: flex;
    width: 100%;
    flex-direction: column;
    .employeeContainer {
      flex: 8;
      display: flex;
        .topProfile {
          display: flex;
          padding: 20px;
          gap: 20px;
  
          .left {
            flex: 1;
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 20px;
            position: relative;
            .editbutton {
              position: absolute;
              top: 0;
              right: 0;
              padding: 5px;
              font-size: 12px;
              color: blue;
              background-color: rgba(190, 190, 235, 0.534);
              border-radius: 0px 0px 0px 5px;
            }
            .item {
              display: flex;
              gap: 20px;
              .itemImg {
                width: 100px;
                height: 100px;
                border-radius: 50;
                object-fit: cover;
                color: lightblue;
              }
              .details {
                .itemTitle {
                  margin-bottom: 10px;
                  color: #555;
                }
                .detailItem {
                  margin-bottom: 10px;
                  font-size: 14px;
                  .itemKey {
                    font-weight: bold;
                    color: gray;
                    margin-right: 10px;
                  }
                  .itemValue {
                    font-weight: 300;
                  }
                }
              }
            }
          }
          .right {
            flex: 2;
            display: flex;
            flex-direction: column;
            padding: 20px;
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            .progressBars {
              display: flex;
              //gap: 20px;
              margin: 20px;
              .progress {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-left: 4px;
                .progressBar {
                  height: 150px;
                  width: 10vw;
                }
              }
            }
          }
        }
        .bottomProfile {
          padding: 20px;
          margin: 20px;
          align-items: center;
          -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
          box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
          .employeeAssessments {
            justify-content: center;
            width: 70vw;
            height: 60vh;
          }
        }
      .title {
        font-size: 16px;
        color: rgb(179, 179, 179);
        margin-bottom: 20px;
      }
      .cellAction{
        display: flex;
        align-items: center;
        .viewButton{
            padding: 2px 5px;
            border-radius: 5px;
            color: darkblue;
            border: 1px solid rgba(0, 0, 139, 0.541);
            cursor: pointer;
        }
    }
    }
  }
  