@font-face {
  font-family: 'MyCustomFont';
  src: url('./Gotham.ttf') format('truetype');
}

.home {
  font-family: 'MyCustomFont';
  #homeComp {
    //display: inherit !important;
  }
  #processComp {
   // display: inherit !important;
  }

  #benefitsComp {
    //display: inherit !important;
  }
}
