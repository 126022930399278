.mainSidebar{
  //border-right: 0.5px solid rgb(175, 172, 172);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  min-height: 100vh;
  min-width: 240px;
  .listTextColor{
    //color : rgb(25, 118, 210);
    color: rgb(94, 91, 91);
  }
}
