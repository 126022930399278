.home {
  display: flex;
  flex-direction: column;
  .homeContainer {
    flex: 6;
    display: flex;
    min-width: 100vw;
    //min-height: 100vh;
   // margin-left: 20px;
    .contentArea {
      display: flex;
      flex-direction: column;
     // min-width: 80vw;
      .topProfile {
        display: flex;
        flex-direction: row;
        padding: 20px;
        gap: 20px;
        width: 75vw;
        .left {
          flex: 1;
          -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
          box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
          padding: 20px;
          //position: relative;
          width: 15vw;
         // height: 15%;
         //width: 100%;
        }
        .right {
          flex: 2;
          display: flex;
          flex-direction: column;
          padding: 20px;
          //min-width: 50vw;
          -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
          box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
          .progressBars {
            display: flex;
            //gap: 20px;
            margin: 20px;
            .progress {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-left: 4px;
              .progressTitle{
               // font-size: 1.5vh;
              }
              .progressBar {
                height: 150px;
                width: 10vw;
              }
            }
          }
        }
      }
    }
    .cellAction{
      display: flex;
      align-items: center;
      .viewButton{
          padding: 2px 5px;
          border-radius: 5px;
          color: darkblue;
          border: 1px solid rgba(0, 0, 139, 0.541);
          cursor: pointer;
      }
  }
    .bottomProfile {
      padding: 20px;
      margin: 20px;
      width: 70vw;
      align-items: center;
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      .employeeAssessments {
        justify-content: center;
        width: 70vw;
        height: 20vw;
      }
    }
    .title {
      font-size: 16px;
      color: rgb(179, 179, 179);
      margin-bottom: 20px;
    }
  }
}
