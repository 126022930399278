.assignAssessment {
  display: flex;
  width: 100%;
  flex-direction: column;
  .assignAssessmentContainer {
    flex: 6;
    display: flex;
    .topAssignAssessment {
      padding: 10px;
      //margin: 20px;
      align-items: center;
      h1 {
        margin-left: 30px;
        color: rgb(150, 148, 148);
        font-size: 40px;
      }
      height: 5%;
    }

    .bottomAssignAssessment {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 20px;
      margin: 20px;
      width: 100%;
      label {
        font-size: large;
        font-weight: 600;
        color: rgb(139, 138, 138);
      }
      .gridItem{
        width: 80%;
      }

/*       .createButtonOld {
        min-width: 150px;
        max-width: 150px;
        padding: 10px;
        border: none;
        color: white;
        background-color: teal;
        font-weight: bold;
      } */
    }
  }
}
