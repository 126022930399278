.employeeList{
    display: flex;
    width: 100%;
    flex-direction: column;
    .employeeListContainer {
        flex: 6;
        display: flex;
        .employeListTitle{
            padding: 10px;
            margin: 20px;
            display: flex;
            h1 {
              margin-left: 0px;
              color: rgb(150, 148, 148);
              font-size: 40px;
            }
            height: 5%;
        }
    }
}