.experiment200 {
    background-color: white;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 20px;
    margin: 20px;

    .gridItem{
        width: 50%;
    }
      .createButton{
        align-items: center;
       // width: 50%;
      }
  }
