.myDataTable{
    width: 70vw;
    height: 70vh;
    padding: 20px;

    .cellAction{
        display: flex;
        align-items: center;
        .viewButton{
            padding: 2px 5px;
            border-radius: 5px;
            color: darkblue;
            border: 1px solid rgba(0, 0, 139, 0.541);
            cursor: pointer;
        }
    }
}