.clock-layout {
    position: relative;
    width: 300px;
    height: 300px;
  }
  
  .clock-face {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .hand {
    position: absolute;
    width: 2px;
    height: 40%;
    top: 50%;
    left: 49%;
    background-color: black;
    transform-origin: 100%;
  }
  
  .hour-hand {
    height: 30%;
  }
  
  .minute-hand {
    height: 40%;
  }
  
  .second-hand {
    height: 50%;
    background-color: red;
  }
  