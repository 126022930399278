.taskPagePaper {
  width: 80vw;
  margin: auto;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;
  padding: 40px 0px;
.css-19kzrtu{
    padding: 0px;
  }
  .taskPageTitle {
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-left: 45%;
    margin-bottom: 10px;
    h1 {
        margin-left: 0px;
        color: rgb(150, 148, 148);
        font-size: 40px;
      }
  }
  .taskPageGrid {
    width: 80vw;
    height: 70vh;
    margin-left: 0px !important;
    padding-left: 0px !important;
  }
}

.endDateFormat{
    display: flex;
    align-items: center;
    justify-content: center;
}
/* button:hover {
    background-color: white ;
  } */