.registrationPaper{
    width: 50vh;
   // height: 50vh;
    margin: 50px auto;
    padding: 30px 20px;
    .companyLogo {
        display: block; /* Ensures the image is a block-level element */
        margin: 0 auto; /* Horizontally centers the image */
        /* You might want to set a max-width and auto height to maintain aspect ratio */
        max-width: 30%;
        height: auto;
    }

    .registrationAvatar{
       // color: blue;
    }

    h2{
        margin: 0;
    }
    .registrationText{
        margin: 10px 0px;
    }
}