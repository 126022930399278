/* body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  * {
    font-family: "Verdana", cursive, sans-serif;
    color: #ffffff;
  }
  
  body {
    background-color: #7cc6fe;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  } */
  
  .questioncard {
    color:#ffffff; ;
    background-color: #252d4a;
    width: 50%;
    //min-height: 200px;
    height: 100%;
    border-radius: 15px;
    padding: 50px;
    box-shadow: 10px 10px 42px 0px rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
  
  .score-section {
    display: flex;
    flex-direction: column;
    font-size: 24px;
    //align-items: center;
    .navStyle{
      color: white;
      text-decoration: underline;
    }
  }
  
  /* QUESTION/TIMER/LEFT SECTION */
  .question-section {
    width: 100%;
    position: relative;
    font-weight: bold;
  }
  
  .question-count {
    margin-bottom: 20px;
  }
  
  .question-count span {
    font-size: 28px;
  }
  
  .question-text {
    margin-bottom: 12px;
  }
  
  .timer {
    height: 5px;
    background: green;
    width: 100%;
    //animation: 5s timer infinite linear;
  }
  @keyframes timer {
    100% {
      width: 0%;
      background: red;
    }
    50% {
      background: orange;
    }
    0% {
      width: 100%;
    }
  }
  .timer-text {
    background: rgb(230, 153, 12);
    padding: 15px;
    margin-top: 20px;
    margin-right: 20px;
    border: 5px solid rgb(255, 189, 67);
    border-radius: 15px;
    text-align: center;
  }
  
  /* ANSWERS/RIGHT SECTION */
  .answer-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .answerBUtton {
    width: 100%;
    font-size: 16px;
    color: #ffffff;
    background-color: #252d4a;
    border-radius: 15px;
    padding: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border: 5px solid #234668;
    cursor: pointer;
    :hover{
      background-color: #555e7d;
    }
     

  }
/*   button:hover {
    background-color: #555e7d !important;
  } */
  
/*   
  .correct {
    background-color: #2f922f;
  }
  
  .incorrect {
    background-color: #ff3333;
  } */
 
  button:focus {
    outline: none;
  }
  
  button svg {
    margin-right: 5px;
  }