.taskMain{
    display: flex;
    padding: 10px;
    justify-content: center;
    width: 100%;
    .myGrid{
        align-items: center !important;
        width: 100%;
        .taskItem{
            justify-content: flex-start;
            display: flex;
           align-items: center;
        //   padding: 0px;
        }
        .taskItemCalendar{
            width: 100%;
            justify-content: center;
            display: flex;
           align-items: stretch;
         //  padding: 0px;
        }
        .taskItemButton{
            justify-content: flex-end;
            display: flex;
           align-items: flex-start;
        }

    }
   

}